import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskIdentityDocument } from 'api/KioskApi/entries';
import { IdentityDocumentSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import { DateManager } from 'utils';

import { ProfilePresentationDataElement } from './types';

interface DocumentsVisibilityConfig {
  fieldsConfiguration?: IdentityDocumentSection;
  shortDateFormat?: string;
}

export const mapDocumentsData = (
  documents?: KioskIdentityDocument[],
  settings?: DocumentsVisibilityConfig
): ProfilePresentationDataElement[] => {
  if (!documents) return [];

  const fieldsConfiguration = settings?.fieldsConfiguration;

  return documents.map((doc) => ({
    header: fieldsConfiguration?.typeField?.isVisible
      ? doc.type?.description
      : undefined,
    sectionData: [
      fieldsConfiguration?.numberField?.isVisible
        ? {
            value: doc.number,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.NUMBER'),
          }
        : undefined,
      fieldsConfiguration?.expiryDateField?.isVisible
        ? {
            value:
              doc.expiryDate &&
              DateManager.getFormattedDate(
                doc.expiryDate,
                settings?.shortDateFormat
              ),
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.EXPIRY_DATE'),
          }
        : undefined,
      fieldsConfiguration?.countryOfIssueField?.isVisible
        ? {
            value: doc.countryOfIssue && doc.countryOfIssue.name,
            label: i18next.t(
              'COMPONENTS.PERSONAL_DETAILS_SECTION.COUNTRY_ISSUE'
            ),
          }
        : undefined,
      fieldsConfiguration?.placeOfIssueField?.isVisible
        ? {
            value: doc.placeOfIssue,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.PLACE_ISSUE'),
          }
        : undefined,
      fieldsConfiguration?.issueDateField?.isVisible
        ? {
            value:
              doc.issueDate &&
              DateManager.getFormattedDate(
                doc.issueDate,
                settings?.shortDateFormat
              ),
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.ISSUE_DATE'),
          }
        : undefined,
      fieldsConfiguration?.issuedByField?.isVisible
        ? {
            value: doc.issuingAuthority,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.ISSUED_BY'),
          }
        : undefined,
      fieldsConfiguration?.dateOfBirthField?.isVisible
        ? {
            value:
              doc.dateOfBirth &&
              DateManager.getFormattedDate(
                doc.dateOfBirth,
                settings?.shortDateFormat
              ),
            label: i18next.t(
              'COMPONENTS.PERSONAL_DETAILS_SECTION.DATE_OF_BIRTH'
            ),
          }
        : undefined,
      fieldsConfiguration?.countryOfBirthField?.isVisible
        ? {
            value: doc.countryOfBirth && doc.countryOfBirth.name,
            label: i18next.t(
              'COMPONENTS.PERSONAL_DETAILS_SECTION.COUNTRY_BIRTH'
            ),
          }
        : undefined,
      fieldsConfiguration?.placeOfBirthField?.isVisible
        ? {
            value: doc.placeOfBirth,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.PLACE_BIRTH'),
          }
        : undefined,
      fieldsConfiguration?.nationalityField?.isVisible
        ? {
            value: doc.nationality && doc.nationality.name,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.NATIONALITY'),
          }
        : undefined,
    ].filter(isDefined),
  }));
};
