export enum FormProperties {
  personalDetails = 'personalDetails',
  addresses = 'addresses',
  emails = 'emails',
  mobiles = 'mobiles',
  phones = 'phones',
  documents = 'documents',
}

export enum PersonalDetailsFormProperties {
  title = 'title',
  lastName = 'lastName',
  firstName = 'firstName',
  middleName = 'middleName',
  secondSurname = 'secondSurname',
  suffix = 'suffix',
  birthday = 'birthday',
  nationality = 'nationality',
  alternateDetails = 'alternateDetails',
}

export enum AlternateDetailsFormProperties {
  lastName = 'lastName',
  firstName = 'firstName',
  language = 'language',
}

export enum AddressFormProperties {
  id = 'id',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  type = 'type',
  country = 'country',
  postalCode = 'postalCode',
  city = 'city',
  state = 'state',
  isPrimary = 'isPrimary',
  languageCode = 'languageCode',
  district = 'district',
}

export enum PhoneFormProperties {
  id = 'id',
  countryCode = 'countryCode',
  phoneNumber = 'phoneNumber',
  type = 'type',
  isPrimary = 'isPrimary',
}

export enum EmailFormProperties {
  id = 'id',
  details = 'details',
  type = 'type',
  isPrimary = 'isPrimary',
}

export enum DocumentFormProperties {
  id = 'id',
  type = 'type',
  number = 'number',
  expiryDate = 'expiryDate',
  issueDate = 'issueDate',
  countryCode = 'countryCode',
  placeOfIssue = 'placeOfIssue',
  issuingAuthority = 'issuingAuthority',
  dateOfBirth = 'dateOfBirth',
  countryOfBirthCode = 'countryOfBirthCode',
  placeOfBirth = 'placeOfBirth',
  nationalityCode = 'nationalityCode',
}

export interface FormValues {
  [FormProperties.personalDetails]?: PersonalDetailsFormValues;
  [FormProperties.addresses]?: AddressFormValues[];
  [FormProperties.emails]?: EmailsFormValues[];
  [FormProperties.mobiles]?: PhonesFormValues[];
  [FormProperties.phones]?: PhonesFormValues[];
  [FormProperties.documents]?: DocumentFormValues[];
}

export interface PersonalDetailsFormValues {
  [PersonalDetailsFormProperties.title]?: string;
  [PersonalDetailsFormProperties.lastName]?: string;
  [PersonalDetailsFormProperties.firstName]?: string;
  [PersonalDetailsFormProperties.middleName]?: string;
  [PersonalDetailsFormProperties.secondSurname]?: string;
  [PersonalDetailsFormProperties.suffix]?: string;
  [PersonalDetailsFormProperties.birthday]?: string;
  [PersonalDetailsFormProperties.nationality]?: string;
  [PersonalDetailsFormProperties.alternateDetails]?: AlternateDetailsFormValues;
}

export interface AlternateDetailsFormValues {
  [AlternateDetailsFormProperties.firstName]?: string;
  [AlternateDetailsFormProperties.lastName]?: string;
  [AlternateDetailsFormProperties.language]?: string;
}

export interface AddressFormValues {
  [AddressFormProperties.id]?: string;
  [AddressFormProperties.addressLine1]?: string;
  [AddressFormProperties.addressLine2]?: string;
  [AddressFormProperties.type]?: string;
  [AddressFormProperties.country]?: string;
  [AddressFormProperties.postalCode]?: string;
  [AddressFormProperties.city]?: string;
  [AddressFormProperties.state]?: string;
  [AddressFormProperties.isPrimary]?: boolean;
  [AddressFormProperties.languageCode]?: string;
  [AddressFormProperties.district]?: string;
}

export interface PhonesFormValues {
  [PhoneFormProperties.id]?: string;
  [PhoneFormProperties.countryCode]?: string;
  [PhoneFormProperties.phoneNumber]?: string;
  [PhoneFormProperties.type]?: string;
  [PhoneFormProperties.isPrimary]?: boolean;
}

export interface EmailsFormValues {
  [EmailFormProperties.id]?: string;
  [EmailFormProperties.details]?: string;
  [EmailFormProperties.type]?: string;
  [EmailFormProperties.isPrimary]?: boolean;
}

export interface DocumentFormValues {
  [DocumentFormProperties.id]?: string;
  [DocumentFormProperties.type]?: string;
  [DocumentFormProperties.number]?: string;
  [DocumentFormProperties.expiryDate]?: string;
  [DocumentFormProperties.issueDate]?: string;
  [DocumentFormProperties.countryCode]?: string;
  [DocumentFormProperties.placeOfIssue]?: string;
  [DocumentFormProperties.issuingAuthority]?: string;
  [DocumentFormProperties.dateOfBirth]?: string;
  [DocumentFormProperties.countryOfBirthCode]?: string;
  [DocumentFormProperties.placeOfBirth]?: string;
  [DocumentFormProperties.nationalityCode]?: string;
}
