import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  DATE_PICKER_DATE_FORMAT,
  DatePickerField,
  Field,
  FieldMode,
  Flex,
  FlexDirection,
  Grid,
  SelectField,
} from '@ac/kiosk-components';

import {
  getCountryEntities,
  getDateTimeFormats,
  getDocumentTypeEntities,
  getNationalitiesEntities,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { DateManager } from 'utils/DateManager';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import { useSubForm } from 'utils/form/subFormApi';
import {
  DocumentFormValues,
  FormProperties,
} from 'views/RegistrationCardEditPersonal/types';

import { SubFormActionHeader } from '../../SubFormActionHeader/SubFormActionHeader';
import { useDocumentsFieldsConfiguration } from '../hooks/useDocumentsFieldsConfiguration';

import { DocumentsFormField } from './DocumentsFormFields';

const TEST_SUFFIXES = {
  indexedHeader: '-indexed-header',
  type: '-type',
  number: '-number',
  expiryDate: '-expiry-date',
  dateOfBirth: '-date-of-birth',
  countryOfIssue: '-country-of-issue',
  issuingAuthority: '-issuing-authority',
  issueDate: '-issue-date',
  nationalityCode: '-nationality-code',
  placeOfBirth: '-place-of-birth',
  placeOfIssue: '-place-of-issue',
  countryOfBirthCode: '-country-of-birth-code',
};

const GRID_TEMPLATE_COLUMNS = 'repeat(12, 1fr)';

interface Props {
  path: [FormProperties.documents, number];
  dataTestSelector?: string;
  removeControlDisabled?: boolean;
  onRemoveClick?: () => void;
}

export const SingleDocumentSubForm = ({
  path,
  dataTestSelector,
  removeControlDisabled,
  onRemoveClick,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const subFormState = useSubForm<DocumentFormValues>(path);
  const [, fieldsIndex] = path;

  const fieldsConfiguration = useDocumentsFieldsConfiguration(path);

  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const documentTypes = useSelector(getDocumentTypeEntities);
  const countries = useSelector(getCountryEntities);
  const nationalities = useSelector(getNationalitiesEntities);

  const documentOptions = useMemo(
    () => mapSelectOptions(documentTypes, 'description', 'id'),
    [documentTypes]
  );

  const countriesOptions = useMemo(
    () => mapSelectOptions(countries, 'name', 'code'),
    [countries]
  );

  const nationalitiesOptions = useMemo(
    () => mapSelectOptions(nationalities, 'name', 'code'),
    [nationalities]
  );

  const isNewDocument = !subFormState.values?.id;

  return (
    <Flex
      direction={FlexDirection.column}
      dataTestSelector={dataTestSelector}
      className="gap-lg"
    >
      <SubFormActionHeader
        title={t('REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.TITLE_OF_SINGLE', {
          count: fieldsIndex + 1,
        })}
        onRemoveClick={onRemoveClick}
        dataTestSelector={dataTestSelector?.concat(TEST_SUFFIXES.indexedHeader)}
        deleteControlEnabled={!removeControlDisabled && isNewDocument}
      />
      <Grid
        gridTemplateColumnsSm={GRID_TEMPLATE_COLUMNS}
        className="gap-lg full-width"
      >
        {fieldsConfiguration.type?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.type?.validator}
              valuePath={fieldsConfiguration.type?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.type
                  )}
                  options={documentOptions}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.TYPE'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  modalTitle={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.TYPE_SELECTION_MODAL_TITLE'
                  )}
                  searchable={false}
                  allowClear={!fieldsConfiguration.type?.required}
                  required={fieldsConfiguration.type?.required}
                  readonly={
                    fieldsConfiguration.type?.readonly || !isNewDocument
                  }
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.number?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.number?.validator}
              valuePath={fieldsConfiguration.number?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.number
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.NUMBER'
                  )}
                  placeholder={t('SHARED.FILL')}
                  mode={FieldMode.numeric}
                  required={fieldsConfiguration.number?.required}
                  readonly={fieldsConfiguration.number?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.expiryDate?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.expiryDate?.validator}
              valuePath={fieldsConfiguration.expiryDate?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <DatePickerField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.expiryDate
                  )}
                  placeholder={t('SHARED.FILL')}
                  fieldDisplayFormat={dateTimeFormats?.shortDateFormat}
                  defaultPickerValue={DateManager.getFormattedDate(
                    propertyConfiguration?.businessDate,
                    DATE_PICKER_DATE_FORMAT
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.EXPIRY_DATE'
                  )}
                  allowClear={!fieldsConfiguration.expiryDate?.required}
                  required={fieldsConfiguration.expiryDate?.required}
                  readonly={fieldsConfiguration.expiryDate?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.countryCode?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.countryCode?.validator}
              valuePath={fieldsConfiguration.countryCode?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.countryOfIssue
                  )}
                  options={countriesOptions}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.COUNTRY_ISSUE'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  modalTitle={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.COUNTRY_OF_ISSUE_SELECTION_MODAL_TITLE'
                  )}
                  searchable={false}
                  allowClear={!fieldsConfiguration.countryCode?.required}
                  required={fieldsConfiguration.countryCode?.required}
                  readonly={fieldsConfiguration.countryCode?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.placeOfIssue?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.placeOfIssue?.validator}
              valuePath={fieldsConfiguration.placeOfIssue?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.placeOfIssue
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.PLACE_ISSUE'
                  )}
                  placeholder={t('SHARED.FILL')}
                  mode={FieldMode.text}
                  required={fieldsConfiguration.placeOfIssue?.required}
                  readonly={fieldsConfiguration.placeOfIssue?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.issueDate?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.issueDate?.validator}
              valuePath={fieldsConfiguration.issueDate?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <DatePickerField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.issueDate
                  )}
                  placeholder={t('SHARED.FILL')}
                  fieldDisplayFormat={dateTimeFormats?.shortDateFormat}
                  defaultPickerValue={DateManager.getFormattedDate(
                    propertyConfiguration?.businessDate,
                    DATE_PICKER_DATE_FORMAT
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.ISSUE_DATE'
                  )}
                  allowClear={!fieldsConfiguration.issueDate?.required}
                  required={fieldsConfiguration.issueDate?.required}
                  readonly={fieldsConfiguration.issueDate?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.issuingAuthority?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.issuingAuthority?.validator}
              valuePath={fieldsConfiguration.issuingAuthority?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.issuingAuthority
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.ISSUED_BY'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  mode={FieldMode.text}
                  required={fieldsConfiguration.issuingAuthority?.required}
                  readonly={fieldsConfiguration.issuingAuthority?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.dateOfBirth?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.dateOfBirth?.validator}
              valuePath={fieldsConfiguration.dateOfBirth?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <DatePickerField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.dateOfBirth
                  )}
                  placeholder={t('SHARED.FILL')}
                  fieldDisplayFormat={dateTimeFormats?.shortDateFormat}
                  defaultPickerValue={DateManager.getFormattedDate(
                    propertyConfiguration?.businessDate,
                    DATE_PICKER_DATE_FORMAT
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.DATE_OF_BIRTH'
                  )}
                  allowClear={!fieldsConfiguration.dateOfBirth?.required}
                  required={fieldsConfiguration.dateOfBirth?.required}
                  readonly={fieldsConfiguration.dateOfBirth?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.countryOfBirthCode?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.countryOfBirthCode?.validator}
              valuePath={fieldsConfiguration.countryOfBirthCode?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.countryOfBirthCode
                  )}
                  options={countriesOptions}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.COUNTRY_BIRTH'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  modalTitle={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.COUNTRY_OF_BIRTH_SELECTION_MODAL_TITLE'
                  )}
                  searchable={false}
                  allowClear={!fieldsConfiguration.countryOfBirthCode?.required}
                  required={fieldsConfiguration.countryOfBirthCode?.required}
                  readonly={fieldsConfiguration.countryOfBirthCode?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.placeOfBirth?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.placeOfBirth?.validator}
              valuePath={fieldsConfiguration.placeOfBirth?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.placeOfBirth
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.PLACE_BIRTH'
                  )}
                  placeholder={t('SHARED.FILL')}
                  mode={FieldMode.text}
                  required={fieldsConfiguration.placeOfBirth?.required}
                  readonly={fieldsConfiguration.placeOfBirth?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.nationalityCode?.visible && (
          <Grid.Item colSpanSm={4}>
            <DocumentsFormField
              validate={fieldsConfiguration.nationalityCode?.validator}
              valuePath={fieldsConfiguration.nationalityCode?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.nationalityCode
                  )}
                  options={nationalitiesOptions}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.FIELD.NATIONALITY'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  modalTitle={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.NATIONALITY_SELECTION_MODAL_TITLE'
                  )}
                  searchable={false}
                  allowClear={!fieldsConfiguration.nationalityCode?.required}
                  required={fieldsConfiguration.nationalityCode?.required}
                  readonly={fieldsConfiguration.nationalityCode?.readonly}
                />
              )}
            </DocumentsFormField>
          </Grid.Item>
        )}
      </Grid>
    </Flex>
  );
};

SingleDocumentSubForm.testSuffixes = TEST_SUFFIXES;
