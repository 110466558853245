/**
 * iPad Chrome Bug Workaround
 * In case page is open in new tab from Shiji Daylight PMS application by window.open
 * On startup chrome calculates wrong size of viewport
 * Because of this every element with height 100vh takes more space than should
 * Situation back to normal and chrome will recalculate viewport properly after events:
 * - resize
 * - orientationchange
 */

import { isDefined } from '@ac/library-utils/dist/utils';

const isWorkaroundNeeded = (viewElement: HTMLDivElement): boolean => {
  const isIpadChrome = /CriOS/i.test(navigator.userAgent);
  const viewHeight = viewElement?.clientHeight;
  const viewportHeight = window.innerHeight;

  return Boolean(
    isIpadChrome && isDefined(viewHeight) && viewHeight > viewportHeight
  );
};

// window scrollable area is equal to wrong calculated viewport, this will block scroll
const blockWindowScroll = (): void => {
  if (window.scrollY === 0) return;
  window.scroll(0, 0);
};

// Events after which workaround is not needed anymore, calculation is correct
const eventsToDisableWorkaround: Array<keyof WindowEventMap> = [
  'resize',
  'orientationchange',
];

export const iPadChromeBugWorkaround = (
  viewElement: HTMLDivElement | null
): (() => void) | undefined => {
  if (!viewElement || !isWorkaroundNeeded(viewElement)) return;

  viewElement.style.height = `${window.innerHeight}px`;
  window.addEventListener('touchend', blockWindowScroll);

  const cleanupWorkaround = (): void => {
    viewElement.style.height = '';
    window.removeEventListener('touchend', blockWindowScroll);
    eventsToDisableWorkaround.forEach((eventName) => {
      window.removeEventListener(eventName, cleanupWorkaround);
    });
  };

  eventsToDisableWorkaround.forEach((eventName) => {
    window.addEventListener(eventName, cleanupWorkaround, { once: true });
  });

  return cleanupWorkaround;
};
