import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Color,
  Flex,
  Grid,
  Icon,
  IconTypes,
  Size,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import { ProfilePresentationDataElement } from 'utils/regCardPresentationDataMappers/types';

import { PersonalSubSection } from '../PersonalSubSection/PersonalSubSection';

type ProfileWrappedSectionData = Omit<
  ProfilePresentationDataElement,
  'isCollapsible'
>;

interface PersonalSubgridSectionProps {
  title: string;
  noDataInfo?: string;
  isMissingRequiredInformation?: boolean;
  data?: ProfileWrappedSectionData[];
  className?: string;
  dataTestSelector?: string;
}

export const PersonalSubgridSection = ({
  data = [],
  className,
  title,
  noDataInfo,
  isMissingRequiredInformation,
  dataTestSelector,
}: PersonalSubgridSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div data-test-selector={dataTestSelector} className={className}>
      <Text
        size={TextSize.lg}
        className="spacing-top-sm"
        dataTestSelector={dataTestSelector?.concat('-title')}
      >
        {title}
      </Text>

      {isMissingRequiredInformation && (
        <Flex
          className="gap-sm spacing-bottom-sm spacing-top-lg"
          alignItems={AlignItems.center}
          dataTestSelector={dataTestSelector?.concat(
            '-missing-required-warning'
          )}
        >
          <Icon type={IconTypes.warning} size={Size.sm} color={Color.warning} />
          <Text>{t('VALIDATION.MISSING_REQUIRED_INFO')}</Text>
        </Flex>
      )}
      {data?.length ? (
        <Grid
          gridTemplateColumnsSm="repeat(2, 1fr)"
          gridTemplateColumnsLg="repeat(3, 1fr)"
          className="gap-lg"
          dataTestSelector={dataTestSelector?.concat('-subgrid-grid')}
        >
          {data.map(({ header, sectionData }, index) => (
            <Grid.Item
              key={`${index}-${header}`}
              dataTestSelector={dataTestSelector?.concat(
                '-subgrid-data-container'
              )}
            >
              <PersonalSubSection
                title={header}
                data={sectionData}
                className="spacing-top-sm"
                dataTestSelector={dataTestSelector?.concat(
                  '-subgrid-data-subsection'
                )}
              />
            </Grid.Item>
          ))}
        </Grid>
      ) : (
        <Text
          weight={TextWeight.light}
          className="spacing-top-sm"
          dataTestSelector={dataTestSelector?.concat('-no-data-provided')}
        >
          {noDataInfo}
        </Text>
      )}
    </div>
  );
};
