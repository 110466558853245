var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":40,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":41,"column":6},"end":{"line":45,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/reservationDetailsSubsection.hbs"),depth0,{"name":"partials/reservationDetailsSubsection","hash":{"sectionData":((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"sectionData") : stack1)},"data":data,"blockParams":blockParams,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"idAndDocuments") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":90,"column":11}}})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"personal") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":6},"end":{"line":58,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":6},"end":{"line":68,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":6},"end":{"line":77,"column":13}}})) != null ? stack1 : "")
    + "\n\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"personal") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"personal") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"class":"guest-details-standard-subsection"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"noDataInfo":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1)) != null ? lookupProperty(stack1,"noDataInfo") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"address") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"withSeparators":"true","class":"guest-details-standard-subsection"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"noDataInfo":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"noDataInfo") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"contact") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"class":"guest-details-standard-subsection"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/guestDetailsSubsection.hbs"),depth0,{"name":"partials/guestDetailsSubsection","hash":{"data":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"idAndDocuments") : stack1)) != null ? lookupProperty(stack1,"data") : stack1),"noDataInfo":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"idAndDocuments") : stack1)) != null ? lookupProperty(stack1,"noDataInfo") : stack1),"subsectionTitle":((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"guestSection") : depth0)) != null ? lookupProperty(stack1,"subsections") : stack1)) != null ? lookupProperty(stack1,"idAndDocuments") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"isSubgrid":"true","class":"guest-details-standard-subsection guest-details-wide-subsection"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"complementaryDetailsSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/complementaryDetails.hbs"),depth0,{"name":"partials/complementaryDetails","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"complementaryDetailsSection") : depth0)) != null ? lookupProperty(stack1,"data") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"preferencesSectionData") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/preferencesSection.hbs"),depth0,{"name":"partials/preferencesSection","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"preferencesSectionData") : depth0)) != null ? lookupProperty(stack1,"data") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"purchaseElements") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/stayEnhancements.hbs"),depth0,{"name":"partials/stayEnhancements","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"purchaseElements") : depth0)) != null ? lookupProperty(stack1,"data") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/consentList.hbs"),depth0,{"name":"partials/consentList","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"consentSection") : depth0)) != null ? lookupProperty(stack1,"data") : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"additional-information-item\">\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalDetailsSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/additionalInformationSection.hbs"),depth0,{"name":"partials/additionalInformationSection","hash":{"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalDetailsSection") : depth0)) != null ? lookupProperty(stack1,"data") : stack1)},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/disclaimer.hbs"),depth0,{"name":"partials/disclaimer","hash":{"content":((stack1 = (depth0 != null ? lookupProperty(depth0,"disclaimerSection") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"registration-card-number\">\n"
    + ((stack1 = container.invokePartial(require("./partials/textGroup.hbs"),depth0,{"name":"partials/textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"registrationCardNumber") : depth0)) != null ? lookupProperty(stack1,"value") : stack1),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"registrationCardNumber") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/signature.hbs"),depth0,{"name":"partials/signature","hash":{"signatureImage":((stack1 = (depth0 != null ? lookupProperty(depth0,"signatureSection") : depth0)) != null ? lookupProperty(stack1,"signature") : stack1),"date":((stack1 = (depth0 != null ? lookupProperty(depth0,"signatureSection") : depth0)) != null ? lookupProperty(stack1,"creationDateTime") : stack1),"title":((stack1 = (depth0 != null ? lookupProperty(depth0,"signatureSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div data-break-page-before=\"true\" /></div>\n\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"consentsBodySection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/consentDescription.hbs"),depth0,{"name":"partials/consentDescription","hash":{"consentsWithBody":((stack1 = (depth0 != null ? lookupProperty(depth0,"consentsBodySection") : depth0)) != null ? lookupProperty(stack1,"consentsWithBody") : stack1)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  :root {\n    --color-dark-gray2: #4b5463;\n    --black-color: #000000;\n  }\n\n  .registration-card-pdf-wrapper {\n    color: var(--black-color);\n  }\n\n  .additional-information-section {\n    display: flex;\n  }\n\n  .additional-information-item {\n    flex: 1;\n  }\n\n  .additional-information-item:not(:last-of-type) {\n    margin-inline-end: 8px;\n  }\n\n  .guest-details-standard-subsection {\n    grid-row: span 1;\n  }\n\n  .guest-details-wide-subsection {\n    grid-column: span 3;\n  }\n\n  .registration-card-number {\n    margin-top: 24px;\n  }\n</style>\n\n<div class=\"registration-card-pdf-wrapper\">\n"
    + ((stack1 = container.invokePartial(require("./partials/header.hbs"),depth0,{"name":"partials/header","hash":{"logo":(depth0 != null ? lookupProperty(depth0,"logo") : depth0),"title":(depth0 != null ? lookupProperty(depth0,"title") : depth0)},"data":data,"blockParams":blockParams,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"subtitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"subtitle") : stack1),"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"guestSection") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":49,"column":2},"end":{"line":92,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"complementaryDetailsSection") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":94,"column":2},"end":{"line":98,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"preferencesSectionData") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":100,"column":2},"end":{"line":104,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"purchaseElements") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":106,"column":2},"end":{"line":110,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\"additional-information-section\" data-avoid-break-page-inside=\"true\">\n    <div class=\"additional-information-item\">\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","hash":{"sectionTitle":((stack1 = (depth0 != null ? lookupProperty(depth0,"consentSection") : depth0)) != null ? lookupProperty(stack1,"title") : stack1)},"fn":container.program(24, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"additionalDetailsSection") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":118,"column":4},"end":{"line":126,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"disclaimerSection") : depth0)) != null ? lookupProperty(stack1,"content") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":129,"column":2},"end":{"line":133,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"registrationCardNumber") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":135,"column":2},"end":{"line":142,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/section.hbs"),depth0,{"name":"partials/section","fn":container.program(34, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"consentsBodySection") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":152,"column":2},"end":{"line":158,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useBlockParams":true});