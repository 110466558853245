import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';

import { IdentityDocumentSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import { getPropertyConfiguration } from 'store/settings/selectors';
import { CompareDateType, createDateCompareValidator } from 'utils/form';
import {
  ConfigurationFieldsMap,
  DefaltValidationRules,
  SubFormFieldsConfiguration,
  useSubFormFieldsConfiguration,
} from 'views/RegistrationCardEditPersonal/hooks/useSubFormFieldsConfiguration';

import {
  DocumentFormProperties,
  DocumentFormValues,
  FormProperties,
  FormValues,
} from '../../../types';

const CONFIGURATION_TO_FORM_FIELD_MAP: ConfigurationFieldsMap<
  keyof IdentityDocumentSection,
  DocumentFormValues
> = {
  expiryDateField: DocumentFormProperties.expiryDate,
  numberField: DocumentFormProperties.number,
  typeField: DocumentFormProperties.type,
  issuedByField: DocumentFormProperties.issuingAuthority,
  issueDateField: DocumentFormProperties.issueDate,
  dateOfBirthField: DocumentFormProperties.dateOfBirth,
  nationalityField: DocumentFormProperties.nationalityCode,
  placeOfBirthField: DocumentFormProperties.placeOfBirth,
  placeOfIssueField: DocumentFormProperties.placeOfIssue,
  countryOfBirthField: DocumentFormProperties.countryOfBirthCode,
  countryOfIssueField: DocumentFormProperties.countryCode,
};

type DocumentsFieldsConfigurationResult = SubFormFieldsConfiguration<
  FormValues,
  DocumentFormValues,
  [FormProperties.documents, number]
>;

export const useDocumentsFieldsConfiguration = (
  path: [FormProperties.documents, number]
): DocumentsFieldsConfigurationResult => {
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const getDocumentsSubFormConfiguration = useSubFormFieldsConfiguration<
    FormValues,
    DocumentFormValues,
    [FormProperties.documents, number]
  >('identityDocumentObjectCollection', path);

  const defaultValidationRules = useMemo(
    (): DefaltValidationRules<FormValues, DocumentFormValues> => ({
      [DocumentFormProperties.expiryDate]: [
        createDateCompareValidator(
          'VALIDATION.DOCUMENT_EXPIRED',
          propertyConfiguration?.businessDate,
          CompareDateType.isSameOrBefore,
          DATE_PICKER_DATE_FORMAT
        ),
      ],
      [DocumentFormProperties.issueDate]: [
        createDateCompareValidator(
          'VALIDATION.INCORRECT_DATE',
          propertyConfiguration?.businessDate,
          CompareDateType.isAfter,
          DATE_PICKER_DATE_FORMAT
        ),
      ],
      [DocumentFormProperties.dateOfBirth]: [
        createDateCompareValidator(
          'VALIDATION.INCORRECT_DATE',
          propertyConfiguration?.businessDate,
          CompareDateType.isAfter,
          DATE_PICKER_DATE_FORMAT
        ),
      ],
    }),
    [propertyConfiguration?.businessDate]
  );

  return getDocumentsSubFormConfiguration(
    CONFIGURATION_TO_FORM_FIELD_MAP,
    defaultValidationRules
  );
};
